<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.discord.title")}}</v-card-title>

        <v-card-text>
            {{$t("support.discord.text")}}

            <v-card flat
                    @click="join_discord"
                    :color="$variables.v.discord_color"
                    @mouseover="discord_hover = true"
                    @mouseout="discord_hover = false"
                    class="discord-card mt-5 pa-5 disable-select animate__animated animate__delay-1s animate__zoomIn">
                <div>
                    <v-icon @click="discord_fall = true"
                            class="discord-icon mr-5 animate__animated"
                            :class="{'animate__infinite animate__heartBeat':discord_hover && !discord_fall, 'animate__hinge':discord_fall}">
                        mdi-discord
                    </v-icon>
                    {{$t("support.discord.join-discord")}}
                </div>
            </v-card>

        </v-card-text>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                discord_hover: false,
                discord_fall : false,
            }
        },
        methods: {
            async join_discord() {
                setTimeout(() => {
                    window.open(this.$variables.v.discord_url, "_blank")
                }, 1000);
            }
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-discord')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>